import React, { useState, useMemo } from "react";
import Modal from "react-modal";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import { loadFromLocalStorage, setOnLocalStorage } from "state/utils";
import { CONFIG_LAST_UPDATED } from "resources/constants";
import Promotion from "./Promotion";
import useConfig from "state/config";
import CloseSVG from "resources/img/close-Icon.svg";
import "./styles.css";
import { useEffect } from "react";
import { Button, ButtonLabel, Title } from "./tutorial-styles";
import useI18n from "state/i18n";

Modal.setAppElement("#root");

const MILLIS_IN_HOUR = 1000 * 60 * 60;

const CloseIcon = styled.img`
  position: absolute;
  right: 6px;
  top: -27px;
  width: 20px;
  cursor: pointer;
  z-index: 5;
`;

const PromotionContent = styled.div`
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
`;

const PickerContent = styled.div`
  padding: 15px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  background-color: white;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getLastUpdated = (lastUpdated) => (!!lastUpdated ? lastUpdated : 0);

const shouldBeOpened = () => {
  const lastUpdated = loadFromLocalStorage(CONFIG_LAST_UPDATED);
  return Date.now() > getLastUpdated(lastUpdated) + MILLIS_IN_HOUR * 12;
};

export default function ConfigDialog() {
  const [alreadyOpenedOnce, setAlreadyOpenedOnce] = useState(false);
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [isPromoOpen, setPromoOpen] = useState(false);
  const [config] = useConfig();
  const [i18n] = useI18n();

  const menuPickerConfig = useMemo(()=>config["menu-picker"]?.payload, [config]);
  const promotionConfig = useMemo(()=>config.promotion?.payload, [config]);

  const pickerEnabled = useMemo(() => {
    if (!menuPickerConfig?.active) return false;
    return true;
  }, [menuPickerConfig]);

  const promoEnabled = useMemo(() => {
    if (!promotionConfig?.active) return false;
    return shouldBeOpened();
  }, [promotionConfig]);

  function handleInitialModal(pickerEnabled, promoEnabled) {
    if (pickerEnabled) {
      setPickerOpen(true);
    } else if (promoEnabled) {
      setPromoOpen(true);
    }
  }

  useEffect(() => {
    if (alreadyOpenedOnce) return;
    handleInitialModal(pickerEnabled, promoEnabled);
  }, [pickerEnabled, promoEnabled]);

  function closePicker() {
    setPickerOpen(false);
    if (promoEnabled) {
      setPromoOpen(true);
      return;
    }
    setAlreadyOpenedOnce(true);
  }

  function closePromo() {
    setPromoOpen(false);
    setOnLocalStorage(CONFIG_LAST_UPDATED, Date.now());
    setAlreadyOpenedOnce(true);
  }

  function handleExternalMenu() {
    if(!!menuPickerConfig.externalMenuURL) {
      window.location.href = menuPickerConfig.externalMenuURL;
      return;
    }
    closePicker();
  }

  return (
    <>
      {pickerEnabled && (
        <Modal
          isOpen={isPickerOpen}
          onRequestClose={closePicker}
          className={"ui-modal"}
          overlayClassName={"ui-background"}
          shouldCloseOnOverlayClick={true}
        >
          <CloseIcon src={CloseSVG} alt="close" onClick={closePicker} />
          <PickerContent>
            <div style={{ maxWidth: "80vw", minWidth: "16em", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', gap: '10px' }}>
              <Title>{!!i18n.lang ? menuPickerConfig.title.en : menuPickerConfig.title.es}</Title>
              <Button type="button" onClick={closePicker}>
                <ButtonLabel>
                  {i18n.tag("DINE_IN")}
                </ButtonLabel>
              </Button>
              <Button type="button" onClick={handleExternalMenu}>
                <ButtonLabel dangerouslySetInnerHTML={{ __html: !!i18n.lang ? menuPickerConfig.externalMenuName.en : menuPickerConfig.externalMenuName.es }} />
              </Button>
            </div>
          </PickerContent>
        </Modal>
      )}
      {promoEnabled && (
        <Modal
          isOpen={isPromoOpen}
          onRequestClose={closePromo}
          className={"ui-modal"}
          overlayClassName={"ui-background"}
          shouldCloseOnOverlayClick={true}
        >
          <CloseIcon src={CloseSVG} alt="close" onClick={closePromo} />
          <PromotionContent backgroundColor={promotionConfig?.backgroundColor}>
            <div style={{ maxWidth: "80vw", minWidth: "16em", height: "70vh", display: "flex" }}>
              <Promotion data={promotionConfig} handleClose={closePromo} />
            </div>
          </PromotionContent>
        </Modal>
      )}
    </>
  );
}
